import * as React from 'react';
import { graphql, PageProps } from 'gatsby';

import Page from '../../components/Page';
import Section from '../../components/Section';
import ProductItem from '../../components/ProductItem';
import { getCmsDocuments, getLayout } from '../../cms';
import * as styles from './index.module.css';
import type { ProductIntro, ProductProducts } from '../../types';

const PAGE_DESCRIPTION =
  'Industriële dompelpompen, centrifugale pompen, volumetrische pompen: maatwerk analoge besturingskasten. Centrifugale pompen: Calpeda. Heavy duty dompelpompen :Faggiolati. Onderwaterpompen: WPS. Drukschakelaars: Condor. Druksensoren. Debietmeting. T- meting: IFM - STS';

const ProductPage: React.FC<PageProps> = ({ data }) => {
  const documents = getCmsDocuments(data);
  const [{ title, html }] = getLayout(
    documents,
    '/products/intro/'
  ) as ProductIntro[];
  const [{ products }] = getLayout(
    documents,
    '/products/products/'
  ) as ProductProducts[];

  return (
    <Page title={title} description={PAGE_DESCRIPTION}>
      <Section>
        <Section.Title>{title}</Section.Title>
        <Section.Description dangerouslySetInnerHTML={{ __html: html }} />
      </Section>
      <Section>
        <Section.Title>Onze producten</Section.Title>
        <div className={styles.products}>
          {products.map((product) => (
            <ProductItem key={product.title} product={product} />
          ))}
        </div>
      </Section>
    </Page>
  );
};

export default ProductPage;

export const pageQuery = graphql`
  query getProductPage {
    allMarkdownRemark(
      filter: {
        fields: {
          filePath: { in: ["/products/intro/", "/products/products/"] }
        }
      }
    ) {
      edges {
        node {
          html
          frontmatter {
            title
            products {
              title
              description
              thumbnail {
                name
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, quality: 60)
                }
              }
            }
          }
          fields {
            filePath
          }
        }
      }
    }
  }
`;
