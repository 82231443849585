import * as React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import * as styles from './index.module.css';
import type { Product } from '../../types';

type Props = {
  product: Product;
};

const ProductItem: React.FC<Props> = ({ product }) => {
  return (
    <article className={styles.container}>
      <GatsbyImage
        className={styles.image}
        image={getImage(product.thumbnail)!}
        alt={product.thumbnail.name}
        objectFit="contain"
      />
      <div className={styles.info}>
        <h4 className={styles.title}>{product.title}</h4>
        <p className={styles.description}>{product.description}</p>
      </div>
    </article>
  );
};

export default ProductItem;
